<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main">
            <div>
                <h4>加盟流程</h4>
                <div class="w75p mga">
                    <el-steps :active="0">
                        <el-step title="新增加盟商" status="finish" icon="el-icon-time"></el-step>
                        <el-step title="待乙方确认" icon="el-icon-question"></el-step>
                        <el-step title="加盟商生效" icon="el-icon-question"></el-step>
                    </el-steps>
                </div>
            </div>
            <div class="pdb30">
                <h4>加盟商信息</h4>
                <el-form :model="projectForm" ref="projectForm" :rules="projectRules" label-width="120px" label-position="left">
                    <el-form-item label="加盟类型" prop="agencyType">
                        <span v-if="company.company.companyType == 2">{{ projectForm.agencyType | initDic(agencyTypeDic) }}</span>
                        <el-select v-else v-model="projectForm.agencyType" @change="agencyTypeChange">
                            <el-option v-for="item in agencyTypeDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-row :gutter="72">
                        <!-- 甲方 -->
                        <el-col :span="12">
                            <el-form-item label="甲方" v-if="company.company.isSystem == 1" prop="companyId">
                                <le-company-under-select 
                                    :isSystem='1'
                                    style="margin: 0 !important;padding: 0 !important;width:240px"
                                    v-model="projectForm.companyId"></le-company-under-select>
                            </el-form-item>
                            <el-form-item label="甲方" v-else>
                                <span>{{ company.company.name || '-' }}</span>
                            </el-form-item>
                            <el-form-item label="加盟商等级" >
                                <span v-if="projectForm.agencyType == 1 || projectForm.agencyType == 3">{{ company.company.agencyLevel+1 }}级加盟商</span>
                                <span v-if="projectForm.agencyType == 2">服务商</span>
                            </el-form-item>
                            <el-form-item label="最大可分配营收比例(%)" >
                                <span>{{ maxRate }}%</span>
                            </el-form-item>
                            <el-form-item :label="projectForm.agencyType == 1 || projectForm.agencyType == 3?'甲方营收比例(%)':'甲方服务费(%)'" prop="rateA">
                                <el-input 
                                    style="width:140px" 
                                    maxlength="6" 
                                    v-model="projectForm.rateA" 
                                    :disabled='projectForm.agencyType == 3'
                                    @input="projectForm.rateA=projectForm.rateA.replace(/^\.+|[^\d]+/g,'')"></el-input>
                            </el-form-item>
                            <el-form-item label="是否需要确认" >
                                <el-radio-group v-model="projectForm.needConfirm">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <!-- 乙方 -->
                        <el-col :span="12">
                            <el-form-item label="乙方" prop="agencyCompanyId">
                                <le-company-noagency-select v-model="projectForm.agencyCompanyId" @input="companyBack" style="margin: 0;padding: 0"></le-company-noagency-select>
                            </el-form-item>
                            <el-form-item label="乙方营收比例(%)" >
                                <span class="a-ml-20" v-if="projectForm.rate || projectForm.rate==='0' || projectForm.rate===0">{{ projectForm.rate }}%</span>
                                <span class="a-ml-20" v-else>-</span>
                            </el-form-item>
                            <el-form-item label="加盟商合同" >
                                <le-upload-file ref="pic" @input="uploadFlie" style="margin: 0;padding: 0"></le-upload-file>
                            </el-form-item>
                            <el-form-item label="客服电话" prop="phone">
                                <el-input 
                                    class="a-ml-20"
                                    style="width:240px" 
                                    maxlength="15" 
                                    v-model="projectForm.phone" 
                                    @input="projectForm.phone=projectForm.phone.replace(/^\.+|[^\d]+/g,'')"></el-input>
                            </el-form-item>
                            <el-form-item label="客诉通知人" prop="phone" label-width='144px' v-if="projectForm.agencyType == 2">
                                <el-button icon="el-icon-plus" size="mini" type="primary" @click="addComplaintUserList">客诉通知人</el-button>
                                <div class="a-flex-rfsc" v-for="(item,index) in complaintUserList" :key="index">
                                    <span>{{ item.userName }} {{ item.mobile }}</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="基础收款商户" prop="baseCompany" label-width='140px'>
                                <el-select v-model="projectForm.baseCompany" style="width:240px" >
                                    <el-option v-for="item in baseCompanyDic"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" @click="cancel" style="margin-left:24px">
                    &nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
        <compalint-user-set ref="complaintUserSet" @handlerSuccess='handleAddComplaint'></compalint-user-set>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import compalintUserSet from './child/compalint-user-set.vue';
export default {
    components: { compalintUserSet },
    computed: {
        ...mapState(["company"]),
    },
    data() {
        var checkRateA = (rule, value, callback) => {
            if(!this.projectForm.rateA){
                callback(new Error('请输入甲方营收比例'))
            }else if(this.projectForm.rateA > this.maxRate){
                callback(new Error('甲方营收比例不得超过最大可分配比例'))
            }else{
                callback()
            }
        };
        return {
            projectForm: {
                companyId: '',
                needConfirm: 0,//是否确认
                agencyCompanyId: '',//乙方
                rate: '',//营收比例
                contractFile: '',//合同文件
                rateA: '',//甲方营收比例
                agencyType: '',
                phone: '',// 客服联系电话
                baseCompany: '',
            },
            projectRules:{
                agencyCompanyId: [{required: true, message:'请选择乙方商户', trigger: 'change'}],//乙方
                baseCompany: [{required: true, message:'请选择基础收款商户', trigger: 'change'}],//乙方
                rateA: [{ validator: checkRateA, trigger: 'change' }],//营收比例
                companyId: [{required: true, message:'请选择甲方商户', trigger: 'change'}]
            },
            maxRate: 0,
            agencyTypeDic: [],
            serviceCompanyInfo: '',
            complaintUserList: [],
            baseCompanyDic: [{
                label: '上海玖电科技有限公司',
                value: 1
            },{
                label: '深圳市源助力科技有限公司',
                value: 2
            }],//基础收款商户
        }
    },
    created() {
        this.$getDic('companyType','select').then(res=>{ 
            if(this.company.company.companyType == 1 && this.company.company.isSystem != 1) {
                this.agencyTypeDic = res.filter(item=>{
                    return item.value != '2'
                })
            }else {
                this.agencyTypeDic = res
            }
            this.projectForm.agencyType = JSON.stringify(this.company.company.companyType) || res[0].value 
            // if(this.company.company.id == 9999999999){
			// 	this.projectForm.agencyType = res[1].value 
			// }else{
			// 	this.projectForm.agencyType = res[0].value 
			// }
        })
        
        // if(this.company.company.companyType == 1) {
        //     this.baseCompanyDic = [{
        //         label: '上海玖电科技有限公司',
        //         value: 1
        //     }]
        // }
        this.$getDic('serviceCompanyInfo').then(res=>{ this.serviceCompanyInfo = res[0]; })
        this.getAgencyMaxRate()
    },
    watch:{
        'projectForm.rateA':{
            handler (val) {
                if(val>this.maxRate){
                    this.projectForm.rate = ''
                }else if(val){
                    this.projectForm.rate = this.maxRate - val
                } else{
                    this.projectForm.rate = ''
                }
            }
        }
    },
    mounted () {
        console.log(this.company.company);
        if(this.company.company.isSystem == 1) {
            this.projectForm.companyId = this.company.company.id
        }
        console.log(this.projectForm);
    },
    methods: {
        agencyTypeChange () {
            this.getAgencyMaxRate()
        },
        submit() {
            this.$refs['projectForm'].validate((valid) => {
                if (valid) {
                    let rate=this.projectForm['rate']*100
                    this.$Axios._post({
                        url: this.$Config.apiUrl.signAgency,
                        params: { 
                            ...this.projectForm,
                            companyType: this.projectForm.agencyType,
                            // companyId: this.projectForm.agencyType == 2 ? this.serviceCompanyInfo.value : '',
                            rate:rate
                        }
                    }).then(async res => {
                        if (res.result.code == 0) {
                            if(this.projectForm.phone){
                                await this.addUpdateCustomerPhone()
                            }
                            this.$route.meta.hash = "del";
                            this.$message.success('新增加盟商成功')
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/franchisee/franchisee-view'
                                })
                            }, 1500)

                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                }
            })
            
        },
        //上传文件
        uploadFlie(val) {
            this.projectForm.contractFile = val
        },
        //选择商户回调
        companyBack(val) {
            console.log(val);
        },
        //获取最大营收比例
        getAgencyMaxRate() {
            let params
            if(this.projectForm.agencyType == 2){
                params = { companyId: this.serviceCompanyInfo.value }
            }else{
                params = { companyId: this.company.company.id }
            }
            this.$Axios._get({
                url: this.$Config.apiUrl.getAgencyMaxRate,
                showLoading: false,
                params: { companyId: this.company.company.id }
            }).then((res) => {
                if (res.result.code == 0) {
                    this.maxRate = res.result.data / 100 == 100 ? 100 : (res.result.data / 100).toFixed(2)
                    if(this.projectForm.agencyType == 3) {
                        // 如果是工程商户，则甲方默认最大分配比例
                        this.projectForm.rateA = this.maxRate
                    }
                }
            })
        },
        
        // 添加加盟商自定义客服电话
        addUpdateCustomerPhone () {
            return new Promise((resolve, reject) => {
                this.$Axios._post({
                    url: this.$Config.apiUrl.addUpdateCustomerPhone,
                    params: { 
                        companyId: this.projectForm.agencyCompanyId,
                        phone: this.projectForm.phone
                    }
                }).then(res => {
                    if (res.result.code != 0) {
                        this.$message.error(res.result.message)
                    }
                    resolve()
                }).catch(_=>{
                    resolve()
                })
            })
        },
        //退出
        cancel() {
            this.$router.back()
        },
        integerFn(value) {
            if(value<0){
                return ''
            }else{
                let reg=/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
                if(reg.test(value)){
                    return value-0
                }else{
                    return ''
                }
            }
        },
        addComplaintUserList (datas) {
            if(!this.projectForm.agencyCompanyId) {
                this.$message.error('请先选择乙方')
                return
            }
            this.$refs['complaintUserSet'].companyId = this.projectForm.agencyCompanyId
            this.$refs['complaintUserSet'].dialogVisible = true
        },
        handleAddComplaint (datas) {
            this.complaintUserList = datas
        }
    }
}
</script>

<style lang="scss" scoped>
</style>